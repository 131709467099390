@import "@/styles/variables.scss"; @import './variables.scss';
@import './mixin.scss';
@import './transition.scss';
@import './element-ui.scss';
// @import './sidebar.scss';
@import './btn.scss';
@import './font.scss';

body {
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}

label {
    font-weight: 700;
}

html {
    height: 100%;
    box-sizing: border-box;
}

#app {
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.no-padding {
    padding: 0px !important;
}

.padding-content {
    padding: 4px 0;
}

a:focus,
a:active {
    outline: none;
}

a,
a:focus,
a:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

div:focus {
    outline: none;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.pr-5 {
    padding-right: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.mr {
    &-5 {
        margin-right: 5px;
    }

    &-15 {
        margin-right: 15px;
    }
}

.block {
    display: block;
}

.pointer {
    cursor: pointer;
}

.inlineBlock {
    display: block;
}

.clearfix {
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}

aside {
    background: #eef1f6;
    padding: 8px 24px;
    margin-bottom: 20px;
    border-radius: 2px;
    display: block;
    line-height: 32px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #2c3e50;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
        color: #337ab7;
        cursor: pointer;

        &:hover {
            color: rgb(32, 160, 255);
        }
    }
}

//main-container全局样式
.app-container {
    padding: 20px;
}

.components-container {
    margin: 30px 50px;
    position: relative;
}

.pagination-container {
    margin-top: 30px;
}

.text-center {
    text-align: center
}

.sub-navbar {
    height: 50px;
    line-height: 50px;
    position: relative;
    width: 100%;
    text-align: right;
    padding-right: 20px;
    transition: 600ms ease position;
    background: linear-gradient(90deg, rgba(32, 182, 249, 1) 0%, rgba(32, 182, 249, 1) 0%, rgba(33, 120, 241, 1) 100%, rgba(33, 120, 241, 1) 100%);

    .subtitle {
        font-size: 20px;
        color: #fff;
    }

    &.draft {
        background: #d0d0d0;
    }

    &.deleted {
        background: #d0d0d0;
    }
}

.link-type,
.link-type:focus {
    color: #337ab7;
    cursor: pointer;

    &:hover {
        color: rgb(32, 160, 255);
    }
}

.filter-container {
    padding-bottom: 10px;

    .filter-item {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 10px;
    }
}

//refine vue-multiselect plugin
.multiselect {
    line-height: 16px;
}

.multiselect--active {
    z-index: 1000 !important;
}

// .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon:before {
//     content: "";
//     position: absolute;
//     height: 14px !important;
//     width: 14px !important;
//     top: 0;
//     left: 0;
//     background-color: #fff;
//     border-radius: 2px;
//     border: 1px solid #dcdfe6;
// }

// .vxe-custom--option .vxe-checkbox--checked-icon:after,
// .vxe-export--panel-column-option .vxe-checkbox--checked-icon:after,
// .vxe-table--filter-option .vxe-checkbox--checked-icon:after,
// .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--checked-icon:after {
//     content: "";
//     position: absolute;
//     height: 14px !important;
//     width: 14px !important;
//     // top: 6px;
//     // left: 7px;
//     border: 1px solid #fff;
//     border-left: 0;
//     border-top: 0;
//     -webkit-transform: translate(-50%, -50%) rotate(45deg);
//     transform: translate(-50%, -50%) rotate(45deg);
// }

.vxe-tree--node-btn {
    transition: transform .2s ease-in-out;
}

.vxe-body--row {
    transition: 0.2s;
}

.allowDrag {
    cursor: move;
}

.edui-default .edui-editor-toolbarbox {
    line-height: 21px;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
    height: 21px !important;
    width: 12px !important;
}

// 自定义
.text {
    &-center {
        text-align: center;
    }

    // &-overflow {
    //     display: inline-block;

    //     white-space: nowrap;

    //     width: 100%;

    //     overflow: hidden;

    //     text-overflow: ellipsis;
    // }

    &-overflow {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

    &-6c6c6c {
        color: #6c6c6c;
    }

    &-left {
        text-align: left;
    }

    &-white {
        color: white;
    }

    &-9A9A9A {
        color: #9a9a9a;
    }

    &-101010 {
        color: #101010;
    }



}
.pos{
    &-r{
        position: relative;
    }
    &-a{
        position: absolute;
    }
}
.z-index{
    &-1{
        z-index: 1;
    }
    &-2{
        z-index: 2;
    }
    &-3{
        z-index: 3;
    }
}
.font-size {
    &-12 {
        font-size: 12px;
    }

    &-14 {
        font-size: 14px;
    }

    &-18 {
        font-size: 18px;
    }
    &-24{
        font-size: 24px
    }
}

.font-weight {
    &-400 {
        font-weight: 400;

    }

    &-500 {
        font-weight: 500;

    }
}

.ml {
    &-5 {
        margin-left: 5px;
    }

    &-10 {
        margin-left: 10px;
    }

    &-15 {
        margin-left: 15px;
    }

    &-20 {
        margin-left: 20px;
    }
    &-30{
        margin-left: 30px;
    
    }
    &-75{
        margin-left: 75px;
    }
}

.px {
    &-15 {
        padding: 0 15px;
    }

    &-20 {
        padding: 0 20px;
    }
}

.flex {
    &-1 {
        flex: 1
    }

    &-2 {
        flex: 2;
    }

    &-3 {
        flex: 3;
    }

}

.my {
    &-10 {
        margin: 10px 0;
    }

    &-20 {
        margin: 20px 0;
    }

    &-50 {
        margin: 50px 0;
    }

    &-auto {
        margin: auto 0;
    }

}

.mt {
    &-10 {
        margin-top: 10px
    }

    &-20 {
        margin-top: 20px
    }

    &-50 {
        margin-top: 50px
    }
}

.mb{
    &-10{
        margin-bottom: 10px 
    }
    &-15{
        margin-bottom: 15px
    }
   
    &-20 {
        margin-bottom: 20px
    }
}

.p {
    &-20 {
        padding: 20px;
    }
}
.pb{
    &-25{
        padding-bottom: 25px;
    }
}

.d-flex {
    display: flex;


}

.align-items {
    &-center {
        align-items: center;

    }
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.justify-content {
    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-end {
        // justify-content: end;
        justify-content: flex-end;
    }
}

.btn-orange {
    background-color: #FF9648 !important;
    color: #FFF !important;
    border: 1px solid #FF9648 !important;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.8;
    }
}