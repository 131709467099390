@import "@/styles/variables.scss"; 


















































































































































































































































































































































































































































































































































































































































































































.mask-popover {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
}
.filter-btn {
    display: inline-block;
    // float: right;
}
.z-index {
    &-3002 {
        z-index: 3002;
    }
}

.text-primary {
    color: #1890ff;
}

.d-flex {
    display: flex;
}

.flex {
    &-1 {
        flex: 1;
    }

    &-2 {
        flex: 2;
    }

    &-3 {
        flex: 3;
    }
}

.font-size-20 {
    font-size: 20px;
}

.text-center {
    text-align: center;
}

.align-items-center {
    align-items: center;
}

.ml-10 {
    margin-left: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.filter-list {
    padding-top: 10px;
    max-height: 200px;
    overflow-y: auto;

    &-item {
        padding: 0 10px;
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0;
        cursor: pointer;
        border-radius: 5px;
        color: rgba(0, 0, 0, 0.55);

        &:hover {
            background: rgba(24, 144, 255, 0.2);
            color: #1890ff;
        }

        &.active {
            background: rgba(24, 144, 255, 0.2);
            color: #1890ff;
        }
    }
}

.filter-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;

    .filter-mask {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 3000;
    }

    .filter-type {
        position: absolute;
        background-color: #fff;
        top: 20px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        z-index: 3001;
    }

    .add-filter-type {
        color: #1890ff;
        display: flex;
        cursor: pointer;
        align-items: center;
        line-height: 25px;

        &-icon {
            font-size: 25px;
            margin-right: 5px;
        }
    }
}

.btn-toolbar {
    // float: right;
    border: none !important;
    margin: 0 12px 0 0 !important;
    padding: 0 0.5em !important;
    height: 32px;
    line-height: 32px;

    &-icon {
        margin-right: 5px;
    }
}

.filter-btn .btn-toolbar:hover,
.filter-btn .btn-toolbar:focus {
    background: transparent !important;
}

.filter-btn .vxe-tools--wrapper {
    .vxe-button {
        border: none !important;
        margin: 0 12px 0 0 !important;
        padding: 0 0.5em !important;
    }
}

.column-list {
    padding: 15px;
    max-height: 300px;
    overflow-y: auto;

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        line-height: 1;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;
    }
}

// .custom-dragging {
// 	background-color:#1890ff;
// }

.sortable-ghost {
    background: #fff !important;
    opacity: 0 !important;
}
.select-box {
    display: flex;
    align-items: center;
    // background: #fff;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    // position: absolute;
    // padding: 15px;
    // border-radius: 10px;
    // z-index: 1;
}

// .column-list-item
