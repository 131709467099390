.font{
	&-size{
		&-12{
			font-size:12px;
		}
		&-13{
			font-size:13px;
		}
		&-14{
			font-size:14px;
		}
	}
}