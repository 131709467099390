@import "@/styles/variables.scss"; 




































































































































































































.columns-btn {
	display: inline-block;
	// float: right;


}

.text-primary {
	color: #1890ff;
}

.d-flex {
	display: flex;
}

.flex {
	&-1 {
		flex: 1;

	}

	&-2 {
		flex: 2;
	}

	&-3 {
		flex: 3;
	}

}

.font-size-20 {
	font-size: 20px;
}

.text-center {
	text-align: center;
}

.align-items-center {
	align-items: center;
}

.ml-10 {
	margin-left: 10px;
}

.mb-10 {
	margin-bottom: 10px;
}







.btn-toolbar {
	// float: right;
	border: none !important;
	margin: 0 12px 0 0 !important;
	padding: 0 0.5em !important;
	height: 32px;
	line-height: 32px;

	&-icon {
		margin-right: 5px;
	}
}

.columns-btn .btn-toolbar:hover,
.columns-btn .btn-toolbar:focus {

	background: transparent !important;
}

.columns-btn .vxe-tools--wrapper {
	.vxe-button {
		border: none !important;
		margin: 0 12px 0 0 !important;
		padding: 0 0.5em !important;
	}



}

.column-list {
	padding: 15px;
	max-height: 300px;
	overflow-y: auto;

	&-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 5px;
		line-height: 1;
		padding: 10px;
		margin-top: 10px;
		border: 1px solid rgba(0, 0, 0, 0.05);
		background: #fff;

		.icon {
			position: relative;
			z-index: 9999;
		}

		// &:hover {
		// 	border: 1px solid #1890ff;
		// 	color: #1890ff;
		// }

	}
}

// .custom-dragging {
// 	background-color:#1890ff;
// }

.sortable-ghost {
	background: #fff !important;
	// opacity: 0 !important;

}

.sortable-chosen {
	.icon-caiddang {
		color: #1890ff;
	}

	background: #fff !important;
	border: 1px solid #1890ff;
	color: #1890ff;
	opacity: 1 !important;
}

// .column-list-item 
